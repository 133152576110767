<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('user_transfer')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('user_transfer')" :is-filter="false">
                </HeaderMobile>
            </template>
            <ValidationObserver ref="transferForm">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <ValidationProvider name="from_email" rules="required|email" v-slot="{valid, errors}">
                            <b-form-group :label="$t('from_email')">
                                <b-input v-model="transferForm.from_user_email"></b-input>
                            </b-form-group>
                            <b-form-invalid-feedback class="pt-0" v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <ValidationProvider name="to_email" rules="required|email" v-slot="{valid, errors}">
                            <b-form-group :label="$t('to_email')">
                                <b-input v-model="transferForm.to_user_email"></b-input>
                            </b-form-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <b-button variant="primary" @click="transfer">{{ $t('transfer') }}</b-button>
                    </div>
                </div>
            </ValidationObserver>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import UserService from '../../../services/UserService'

export default {
    components: {
        AppLayout,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile
    },
    metaInfo() {
        return {
            title: this.$t('user_transfer')
        }
    },
    data() {
        return {
            transferForm: {}
        }
    },
    methods: {
        async transfer() {
            const isValid = await this.$refs.transferForm.validate()

            if (isValid) {
                UserService.transfer(this.transferForm)
                    .then((response) => {
                        this.$toast.success(this.$t('api.' + response.data.message))  
                    }).catch((error) => {
                        this.$toast.error(this.$t('api.' + error.data.message))  
                    })
            }
        }
    }
}
</script>

